import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import installElementPlus from './plugins/element'
import request from '@/api'
import utils from '@/utils'
import store from './store'
import '@/router/jurisdiction'
import '@/icons/iconfont.css'

const app = createApp(App)

app.config.globalProperties.$http = request
app.config.globalProperties.$utils = utils

installElementPlus(app)
app.use(router).use(store).mount('#app')