const need_jurisdiction = [{
    path: '/',
    component: () => import('@/layout/layout'),
    redirect: '/home',
    children: [{
            path: '/account',
            component: () => import('@/views/account/account')
        },
        {
            path: '/RoleManagement',
            component: () => import('@/views/RoleManagement/RoleManagement')
        },
        {
            path: '/ModuleManagement',
            component: () => import('@/views/ModuleManagement/ModuleManagement')
        },
        {
            path: '/ChangeInformation',
            component: () => import('@/views/ChangeInformation/ChangeInformation')
        },
        {
            path: '/ChangePassword',
            component: () => import('@/views/ChangePassword/ChangePassword')
        },
        {
            path: '/coupon',
            component: () => import('@/views/coupon/coupon'),
        },
        {
            path: '/home',
            component: () => import('@/views/home/home'),
        },
        {
            path: '/ServiceClassification',
            component: () => import('@/views/ServiceClassification/ServiceClassification'),
        },
        {
            path: '/OrderClassification',
            component: () => import('@/views/OrderClassification/OrderClassification'),
        },
        {
            path: '/GoodsManagement',
            component: () => import('@/views/GoodsManagement/GoodsManagement'),
        },
        {
            path: '/ServiceManagement',
            component: () => import('@/views/ServiceManagement/ServiceManagement')
        },
        {
            path: '/ReturnRefund',
            component: () => import('@/views/ReturnRefund/ReturnRefund')
        }, {
            path: '/attribute',
            component: () => import('@/views/attribute/attribute')
        }, {
            path: '/goodsList',
            component: () => import('@/views/goodsList/goodsList')
        }, {
            path: '/analysisSystem',
            component: () => import('@/views/analysisSystem/analysisSystem')
        }, {
            path: '/ActivityHistory',
            component: () => import('@/views/ActivityHistory/ActivityHistory')
        }, {
            path: '/ShopInformation',
            component: () => import('@/views/ShopInformation/ShopInformation')
        }, {
            path: '/AccountAgreement',
            component: () => import('@/views/AccountAgreement/AccountAgreement')
        }, {
            path: '/Helper',
            component: () => import('@/views/Helper/Helper')
        }, {
            path: '/PushManagement',
            component: () => import('@/views/PushManagement/PushManagement')
        }, {
            path: '/BookingTime',
            component: () => import('@/views/BookingTime/BookingTime')
        }, {
            path: '/getBookingList',
            component: () => import('@/views/getBookingList/getBookingList')
        }
    ]
}]

export default need_jurisdiction