import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
//路由
import no_jurisdiction from './path/no_jurisdiction'
import need_jurisdiction from './path/need_jurisdiction'

const routes = [...no_jurisdiction, ...need_jurisdiction]
console.log(process.env)
const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router