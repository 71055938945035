import tw from 'element-plus/lib/locale/lang/zh-tw' //繁体
import locale, {
  use
} from 'element-plus/lib/locale'
import {
  ElButton,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElLoading,
  ElContainer,
  ElHeader,
  ElAside,
  ElMain,
  ElMenu,
  ElSubmenu,
  ElMenuItem,
  ElImage,
  ElRadioButton,
  ElRadioGroup,
  ElTooltip,
  ElPopover,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElDialog,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElCollapse,
  ElCollapseItem,
  ElSelect,
  ElOption,
  ElTree,
  ElDatePicker,
  ElInputNumber,
  ElCascader,
  ElTag,
  ElTabs,
  ElTabPane,
  ElStep,
  ElSteps,
  ElRadio,
  ElUpload,
  ElCheckbox,
  ElCascaderPanel,
  ElAlert,
  ElCard,
  ElTimePicker,
  ElEmpty,
  ElTimeSelect
} from 'element-plus'

export default (app) => {
  locale.use(tw)
  app.use(ElTimeSelect)
  app.use(ElEmpty)
  app.use(ElTimePicker)
  app.use(ElButton)
  app.use(ElRow)
  app.use(ElCol)
  app.use(ElForm)
  app.use(ElFormItem)
  app.use(ElInput)
  app.use(ElMessage)
  app.use(ElLoading)
  app.use(ElContainer)
  app.use(ElHeader)
  app.use(ElAside)
  app.use(ElMain)
  app.use(ElMenu)
  app.use(ElSubmenu)
  app.use(ElMenuItem)
  app.use(ElImage)
  app.use(ElRadioButton)
  app.use(ElRadioGroup)
  app.use(ElRadio)
  app.use(ElTooltip)
  app.use(ElPopover)
  app.use(ElBreadcrumb)
  app.use(ElBreadcrumbItem)
  app.use(ElDialog)
  app.use(ElTable)
  app.use(ElTableColumn)
  app.use(ElPagination)
  app.use(ElCollapse)
  app.use(ElCollapseItem)
  app.use(ElSelect)
  app.use(ElOption)
  app.use(ElTree)
  app.use(ElDatePicker)
  app.use(ElInputNumber)
  app.use(ElCascader)
  app.use(ElTag)
  app.use(ElTabs)
  app.use(ElTabPane)
  app.use(ElStep)
  app.use(ElSteps)
  app.use(ElUpload)
  app.use(ElCheckbox)
  app.use(ElCascaderPanel)
  app.use(ElAlert)
  app.use(ElCard)
}