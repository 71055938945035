import {
  createStore
} from 'vuex'
import getter from './getter'

const moduleFiles = require.context('./modules', true, /\.js$/)

const modules = moduleFiles.keys().reduce((res, path) => {
  const name = path.replace(/^\.\/(\w+)\/\w+\.\w+$/, '$1')
  res[name] = moduleFiles(path).default
  return res
}, {})

export default createStore({
  modules,
  getter
})