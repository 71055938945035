import axios from './request'
const config = {
    headers: {
        "Content-Type": "multipart/form-data"
    }
}

/* 所有请求接口 */
const request = {
    // 登錄
    adminLogin(data) {
        return axios.post('admin/adminLogin', data)
    },
    // 获取管理员权限
    getAdminArticle(data) {
        return axios.get('admin/getAdminArticle', {
            params: data
        })
    },
    // 获取管理员列表
    getAdminsList(data) {
        return axios.post('admin/getAdminsList', data)
    },
    // 获取用户列表
    getUsers(data) {
        return axios.post('users/getUsers', data)
    },
    // 添加管理员
    addAdmins(data) {
        return axios.post('admin/addAdmins', data)
    },
    // 删除管理员
    delAdmins(data) {
        return axios.post('admin/delAdmins', data)
    },
    // 编辑管理员信息
    updateAdmin(data) {
        return axios.post('admin/updateAdmin', data)
    },
    // 添加用户
    addUsers(data) {
        return axios.post('users/addUsers', data)
    },
    // 删除用户
    delUser(data) {
        return axios.post('users/delUser', data)
    },
    // 编辑用户信息
    editUsers(data) {
        return axios.post('users/editUsers', data)
    },
    // 更改用户密码
    updatePass(data) {
        return axios.post('users/updatePass', data)
    },
    // 獲取模組列表
    allMenuList(data) {
        return axios.post('admin/allMenuList', data)
    },
    // 编辑模组信息
    updateMenu(data) {
        return axios.post('admin/updateMenu', data)
    },
    // 删除模组
    delMenu(data) {
        return axios.post('admin/delMenu', data)
    },
    // 獲取用戶信息
    getAdminInfo(data) {
        return axios.get('admin/getAdminInfo', {
            params: data
        })
    },
    // 修改个人信息
    updInfo(data) {
        return axios.post('admin/updInfo', data)
    },
    // 修改登录密码
    updPass(data) {
        return axios.post('admin/updPass', data)
    },
    // 获取用户优惠券
    getCouponList(data) {
        return axios.post('coupon/getCouponList', data)
    },
    // 添加/编辑优惠券
    handleCoupon(data) {
        return axios.post('coupon/handleCoupon', data)
    },
    // 添加/编辑优惠券
    delCoupon(data) {
        return axios.post('coupon/delCoupon', data)
    },
    // 上傳文件
    upload(data) {
        return axios.post('upload/uploadFiles', data, config)
    },
    // 添加商品分類
    addGoodsSort(data) {
        return axios.post('sort/addGoodsSort', data)
    },
    // 獲取商品分類
    getGoodsSort(data) {
        return axios.post('sort/getGoodsSort', data)
    },
    // 刪除商品分類
    delGoodsSort(data) {
        return axios.post('sort/delGoodsSort', data)
    },
    // 編輯商品分類
    editGoodsSort(data) {
        return axios.post('sort/editGoodsSort', data)
    },
    // 獲取所有商品分類
    getAllGoodsSort(data) {
        return axios.post('sort/getAllGoodsSort', data)
    },
    // 添加商品屬性值
    addGoodsAttrValues(data) {
        return axios.post('sort/addGoodsAttrValues', data)
    },
    // 獲取商品屬性值列表
    getGoodsAttrValues(data) {
        return axios.post('sort/getGoodsAttrValues', data)
    },
    // 編輯商品屬性值列表
    editGoodsAttrValues(data) {
        return axios.post('sort/editGoodsAttrValues', data)
    },
    // 刪除商品屬性值列表
    delGoodsAttrValues(data) {
        return axios.post('sort/delGoodsAttrValues', data)
    },
    // 獲取所有商品屬性值
    geAlltGoodsAttrValues(data) {
        return axios.post('sort/geAlltGoodsAttrValues', data)
    },
    // 添加商品分類參數
    addGoodsAttr(data) {
        return axios.post('sort/addGoodsAttr', data)
    },
    // 獲取商品分類參數列表
    getGoodsAttr(data) {
        return axios.post('sort/getGoodsAttr', data)
    },
    // 編輯商品分類參數
    editGoodsAttr(data) {
        return axios.post('sort/editGoodsAttr', data)
    },
    // 刪除商品規格屬性值
    delGoodsAttrCombine(data) {
        return axios.post('sort/delGoodsAttrCombine', data)
    },
    // 刪除商品規格
    delGoodsAttr(data) {
        return axios.post('sort/delGoodsAttr', data)
    },
    // 添加商品
    addGoods(data) {
        return axios.post('goods/addGoods', data)
    },
    // 獲取商品列表
    getGoodsList(data) {
        return axios.post('goods/getGoodsList', data)
    },
    // 編輯商品信息
    editGoodsInfo(data) {
        return axios.post('goods/editGoodsInfo', data)
    },
    // 編輯商品信息
    editGoodsSkus(data) {
        return axios.post('goods/editGoodsSkus', data)
    },
    // 編輯商品信息
    editGoodsPicture(data) {
        return axios.post('goods/editGoodsPicture', data)
    },
    // 刪除商品
    delGoods(data) {
        return axios.post('goods/delGoods', data)
    },
    // 獲取訂單管理列表
    getOrderManagementList(data) {
        return axios.post('order/getOrderManagementList', data)
    },
    // 刪除訂單管理列表
    delOrderManagementList(data) {
        return axios.post('order/delOrderManagementList', data)
    },
    // 獲取分析系統列表
    getGoodsAnalysis(data) {
        return axios.post('goods/getGoodsAnalysis', data)
    },
    // 獲取活動歷程列表
    getActivity(data) {
        return axios.post('admin/getActivity', data)
    },
    // 刪除商品選中規格
    delGoodsSelectSkus(data) {
        return axios.post('goods/delGoodsSelectSkus', data)
    },
    // 生成發票PDF
    createInvoice(data) {
        return axios.post('createInvoice', data)
    },
    // 獲取商鋪信息
    getShopsData(data) {
        return axios.get('shops/getShopsData', data)
    },
    // 修改商鋪信息
    updateShopsData(data) {
        return axios.post('shops/updateShopsData', data)
    },
    // 獲取用戶協議/幫助與客服
    getClause(data) {
        return axios.post('clause/getClause', data)
    },
    // 修改獲取用戶協議/幫助與客服
    editClause(data) {
        return axios.post('clause/editClause', data)
    },
    // 推送消息
    pushAds(data) {
        return axios.post('admin/pushAds', data)
    },
    // 獲取訂位時間列表
    getBookingTime(data) {
        return axios.post('shops/getBookingTime', data)
    },
    // 刪除訂位時間列表
    delBookingTime(data) {
        return axios.post('shops/delBookingTime', data)
    },
    // 添加/修改訂位時間列表
    handleBookingTime(data) {
        return axios.post('shops/handleBookingTime', data)
    },
    // 添加/修改訂位時間列表
    getBookingList(data) {
        return axios.post('booking/getBookingList', data)
    },
}



export default request