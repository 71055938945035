import axios from 'axios'
import $utils from '@/utils'
import {
    ElLoading,
    ElMessage
} from 'element-plus'

const $loading = ElLoading
const $message = ElMessage

const service = axios.create({
    baseURL: process.env.VUE_APP_PUBLICURL,
    timeout: 60000 // 请求超时
})

let loading = '' // 接受加载返回值
// 请求拦截器
service.interceptors.request.use(
    config => {
        loading = $loading.service({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        // 在请求发生前可以做的操作
        if ($utils.getCookie('token')) {
            // 让每个请求携带token
            config.headers['TOKEN'] = $utils.getCookie('token');
        } else {
            config.headers['TOKEN'] = '';
        }
        return config
    },
    error => {
        // 处理请求错误
        console.log(error) // for debug
        loading.close();
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        // 如果自定义代码不是200，则判断为错误。
        if (res.status !== 200) {
            if (res.status !== 405) {
                $message({
                    message: res.msg || '请求失败',
                    type: 'error',
                    duration: 5 * 1000
                })
            } else { //登錄失效
                // to re-login
                $message({
                    message: '登錄失效',
                    type: 'error',
                    duration: 5 * 1000
                })
                const timer = setTimeout(() => {
                    $utils.Setcookie('token', '', true)
                    $utils.Setcookie('vue_admin_template_token', '', true) // 未解决的坑
                    window.location.reload();
                    clearTimeout(timer)
                }, 1000);
            }
            loading.close();
            return res
        } else {
            loading.close();
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        loading.close();
        $message({
            message: error.msg || '响应错误',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)
export default service