import router from './index'
import $utils from '@/utils'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'

//路由
import no_jurisdiction from './path/no_jurisdiction'
import need_jurisdiction from './path/need_jurisdiction'

router.beforeEach((to, form, next) => {
    
    NProgress.start();
    if ($utils.getCookie('token')) { // 已登录
        NProgress.done();
        next()
    } else { // 没有登录
        if (no_jurisdiction.some(item => item.path == to.path)) return next(), NProgress.done();
        NProgress.done();
        next({
            path: '/login'
        })
    }
    store.commit('SET_SELElEPATH', to.path.replace(/^\/(.+)$/, "$1"))
})