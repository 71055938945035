import $http from '@/api'
import {
    ElLoading,
    ElMessage
} from 'element-plus'

const $loading = ElLoading
const $message = ElMessage

const state = {
    allMenuList: [], // 菜单列表
    crumbs: [], // 面包屑
    selePath: '', // 选中的path
}

const mutations = {
    async SET_ALLMENULIST(state) {
        const res = await $http.getAdminArticle()
        if (res.status !== 200) return
        // $message.success('获取成功')
        const menu = Object.values(res.data)
        menu.forEach(item => {
            item.children = Object.values(item.children)
        })
        state.allMenuList = menu
    },
    SET_CRUMBS(state, payload) {
        state.crumbs = payload
    },
    SET_SELElEPATH(state, payload) {
        state.selePath = payload
        sessionStorage.setItem(
            "selectPath",
            payload
        );
    }
}

const actions = {
    REQ_ALLMENULIST({
        commit
    }) {
        commit('SET_ALLMENULIST')
    }
}

export default {
    state,
    mutations,
    actions
}