<template>
  <div class="height_100">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "./styles/index.scss";
#app{
  height: 100%;
}
</style>
